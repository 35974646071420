<template>
  <div class="bgc-container">
    <div class="container">
      <img class="bg-img" src="../../../assets/images/bindCard-bg.png" />
      <div class="wx-card" v-show="idWZ" @click="goBindRecord">
        {{$t('bind.rightTitle')}}
      </div>
      <div class="form">
        <div class="form-box">
          <input type="text" v-model="giftCardNo" maxlength="12" :placeholder="$t('bind.placeholder1')" />
          <input type="password" style="margin-top:16px" maxlength="6" v-model="password" :placeholder="$t('bind.placeholder2')" />
          <button :disabled="disabled" @click="submit">{{$t('bind.submit')}}</button>
        </div>
        <div class="tips">
          <div class="tips-title">{{$t('bind.tipsTitle')}}</div>
          <div class="tips-detail" style="margin-top:10px">
            {{$t('bind.tipsDetail1')}}
          </div>
          <div class="tips-detail" style="margin-top:6px">
            {{$t('bind.tipsDetail2')}}
          </div>
          <div class="tips-detail" style="margin-top:6px">
            {{$t('bind.tipsDetail3')}}
          </div>
          <div class="tips-detail" style="margin-top:6px">
            {{$t('bind.tipsDetail4')}}
          </div>
          <div class="tips-detail" style="margin-top:6px">
            {{$t('bind.tipsDetail5')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue"
import { Toast } from 'vant'
import { getEnv, initIosAndAndroidEnv, initIosAndAndroidCallBack, GetQueryValue } from "../../../utils/index"
import { bindGiftCard } from '@/api/giftCard'
import { useI18n } from 'vue-i18n'

export default {
  setup () {
    const { t } = useI18n()  //t('index.rightTitle')
    const flag = ref(false)
    //是否是微信和支付宝环境
    const idWZ = ref(false)
    //token
    const token = ref('')
    //form表单
    const giftCardNo = ref('')
    const password = ref('')
    //确认按钮默认置灰
    const disabled = ref(true)
    //监听表单数据
    const reg = new RegExp(/^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).*$/)
    watch([giftCardNo, password], (newValue) => {
      if (newValue[0].length === 12 && newValue[1].length === 6) {
        disabled.value = false
      } else {
        disabled.value = true
      }
    })
    return {
      idWZ,
      token,
      giftCardNo,
      password,
      disabled,

    }
  },
  data () {
    return {
    }
  },
  created () {
    // this.$i18n.locale = 'zh-CN'
  },
  mounted () {
    let token = localStorage.getItem('token')
    let urlToken = GetQueryValue('token')
    console.log("urlToken", urlToken)
    if (!token || token == 'null') {
      if (!urlToken || urlToken == 'null') {
        this.token = '无token'
        console.log(token)
        this.flag = false
        Toast.fail(this.$t('bind.toastContent1'))
      } else {
        this.flag = true
        this.token = urlToken
      }
      //无token
    } else {
      this.flag = true
      this.token = token
    }
    // localStorage.setItem('token',this.token)
    this.init()
  },
  watch () {
  },
  methods: {
    init () {
      let ENV = getEnv()
      if (ENV === 'ios' || ENV === 'andriod') {
        this.idWZ = false
        initIosAndAndroidEnv(ENV, "navigation/configNavTitle", "bindCard-20220811", {
          "title": this.$t('bind.envTitle'),
          "color": "#000000"
        })
        initIosAndAndroidEnv(ENV, "navigation/configNavMenu", "bindRecord-20020302", {
          "items": [
            {
              "color": "#000000",
              "markNumber": 0,
              "icon": "",
              "text": this.$t('bind.rightTitle'),
              "eventName": "goBindRecord",
            },
          ]
        })
        initIosAndAndroidCallBack(ENV, [{
          name: "goBindRecord",
          callback: this.goBindRecord
        }])
      } else {
        this.idWZ = true
      }
    },
    goBindRecord () {
      this.$router.push('/giftCardRecord')
    },
    submit () {
      if (!this.flag) {
        //无token
        console.log(this.flag)
        Toast.fail(this.$t('bind.toastContent1'))
        return
      }
      bindGiftCard({
        giftCardNo: this.giftCardNo,
        password: this.password
      }).then(res => {
        if (res.success) {
          Toast(this.$t('bind.toastContent2'))
          setTimeout(() => {
            window.location.href = '/app/recharge.html'
          }, 500)
        } else {
          Toast(res.message)
        }
        console.log("res:", JSON.stringify(res))
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .bgc-container {
    z-index: -999;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    overflow: scroll;
    .container {
      position: relative;
      // width: 375px;
      .bg-img {
        // background-color: #FFFFFF;
        width: 100%;
        height: 100%;
      }
      .wx-card {
        position: absolute;
        top: 0;
        padding: 20px;
        width: 100%;
        text-align: right;
        color: #666;
        font-size: 14px;
      }
      .form {
        width: 100%;
        padding: 0 20px;
        position: absolute;
        top: 40%;
        .form-box {
          box-shadow: 0px 4px 10px rgba($color: #d9d9e5, $alpha: 0.4);
          padding: 24px 20px;
          border-radius: 12px;
          margin: 0 auto;
          background-color: #ffffff;
          input {
            width: 100%;
            border: none;
            box-sizing: border-box;
            padding: 16px;
            background-color: #f6f6f6;
            color: #020a19;
            font-size: 15px;
            border-radius: 8px;
          }
          button {
            margin-top: 28px;
            color: #ffffff;
            padding: 13px 0;
            font-size: 16px;
            line-height: 22px;
            width: 75%;
            border: none;
            background: linear-gradient(to right, #3d414b, #17191e);
            border-radius: 8px;
            &:disabled {
              background: #ebebeb;
              color: #a5a7ad;
            }
          }
        }
        .tips {
          padding-bottom: 10px;
          // padding-bottom: 20px;
          margin-top: 24px;
          text-align: left;
          .tips-title {
            font-size: 12px;
            color: #999999;
          }
          .tips-detail {
            font-size: 11px;
            color: #aaaaaa;
            line-height: 16px;
          }
        }
      }
    }
  }
</style>