import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getSign, getParamsUrl } from "../utils/index";

// create an axios instance
const service = axios.create({
  // baseURL: '/api',
  // timeout: 15000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  async config => {
    // 加密后台接口数据
    let _params = {}
    let token = localStorage.getItem('token');
    let appid = 'c543eae4aa0a49fdab3ed761f6345001';
    let noncestr = (new Date()).valueOf();
    _params = Object.assign({},{
      appid: appid,
      noncestr,
    }, config.data);
    delete _params.needSign
    const sign = getSign(_params);
    _params.sign = sign
    config.data = _params
    console.log("token:",token)
    console.log(JSON.stringify(_params))
    config.headers['token'] = token
    // do something before request is sent
    if (config.method == 'post') {
      config.headers['Content-Type'] = 'application/json';
    }else{
      config.url = config.url + '?' + getParamsUrl(_params);
      console.log("config.url:,",config.url)
    }
    let language = localStorage.getItem('Accept-Language')
    if(language){
      config.headers['Accept-Language'] = language
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async response => {
    const res = response.data
    if (res.code == -100) {
    //   ElMessage({
    //     showClose: true,
    //     message: `登录超时!`,
    //     type: 'error'
    //   })

    } else {
      return res
    }
  },
  error => {
    ElMessage({
      showClose: true,
      message: error.message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
