import request from '@/global/request'

export function bindGiftCard(data){
    return request({
        url: '/appRest/rest/api/pass/giftCard/bind',
        method: 'post',
        data: data
    })
}

export function bindGiftCardList(data){
    return request({
        url: '/appRest/rest/api/pass/giftCard/bindRecord/list',
        method: 'get',
        data: data
    })
}